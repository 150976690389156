import {
	Button,
	Field,
	FieldDescription,
	FieldErrors,
	Form,
	Heading,
	HiddenFields,
	Input,
	Label,
	Option,
	RadioBar,
	RadioBarButton,
	RadioGroup,
	Section,
	Select,
	TextField,
} from '@troon/ui';

import { IconCircleCheckCustom } from '@troon/icons/circle-check-custom';
import { Switch, Match, For, Suspense, createSignal, createEffect } from 'solid-js';
import dayjs from '@troon/dayjs';
import { clientAction } from '@troon/api-client';
import { useSearchParams, useSubmission } from '@solidjs/router';
import type { ApiBody } from '@troon/api-client';

type PriveFacility = {
	id: string;
	slug: string;
	name: string;
	timezone: string;
};

export default function PriveTeeTimeForm(props: {
	facilities: Array<PriveFacility>;
	priveRequestType: ApiBody<'post', '/v0/visa/prive/requests'>['priveRequestType'];
}) {
	const submission = useSubmission(requestTeeTime);
	const [timezone, setTimezone] = createSignal<string>('UTC');
	const [query] = useSearchParams<{ facility?: string }>();

	createEffect(() => {
		const facility = props.facilities.find((f) => f.slug === query.facility);
		facility && setTimezone(facility.timezone);
	});

	return (
		<Section appearance="contained">
			<Switch>
				<Match when={submission.result?.id}>
					<div class="container mx-auto flex max-w-xl flex-col items-center gap-6 text-center">
						<IconCircleCheckCustom class="text-8xl" />
						<Heading as="h2" size="h2">
							Request received!
						</Heading>
						<p class="text-lg">
							We have received your Troon Privé member request to reserve a tee time at one of our participating private
							clubs. Your desired destination club will be in touch to confirm your reservation.
						</p>
					</div>
				</Match>
				<Match when>
					<Form data-testId="form" action={requestTeeTime}>
						<HiddenFields data={{ priveRequestType: props.priveRequestType, tz: timezone() }} />

						<Field name="facilityId" required>
							<Label>Club you desire to play</Label>
							<Select
								onChange={(e) => {
									const facility = props.facilities.find((f) => f.id === e.target.value);
									facility && setTimezone(facility.timezone);
								}}
							>
								<Option disabled selected={!query.facility} />
								<Suspense>
									<For each={props.facilities.sort((a, b) => compare(a.name, b.name))}>
										{(club) => (
											<Option selected={query.facility === club.slug} value={club.id}>
												{club.name}
											</Option>
										)}
									</For>
								</Suspense>
							</Select>
							<FieldErrors />
						</Field>

						<TextField name="__dayTime" required>
							<Label>Date you desire to play</Label>
							<Input data-testId="datetime" type="datetime-local" min={dayjs().format('YYYY-MM-DDThh:mm')} />
						</TextField>

						<RadioGroup name="guests">
							<Label>Total number of guests</Label>
							<RadioBar>
								<RadioBarButton value="0" checked>
									<Label>None</Label>
								</RadioBarButton>
								<RadioBarButton value="1">
									<Label>1</Label>
								</RadioBarButton>
								<RadioBarButton value="2">
									<Label>2</Label>
								</RadioBarButton>
								<RadioBarButton value="3">
									<Label>3</Label>
								</RadioBarButton>
							</RadioBar>
							<FieldDescription>Select the total number of non-member guests excluding yourself.</FieldDescription>
						</RadioGroup>

						<TextField name="comments">
							<Label>Additional comments</Label>
							<Input multiline />
						</TextField>

						<Button type="submit" class="size-fit">
							Submit Request
						</Button>
					</Form>
				</Match>
			</Switch>
		</Section>
	);
}

const compare = new Intl.Collator('en').compare;

const requestTeeTime = clientAction(
	'post',
	'/v0/visa/prive/requests',
	{},
	{
		transformer: (data) => ({
			dayTime: {
				epoch: dayjs.tz(data.get('__dayTime') as string, data.get('tz') as string).valueOf(),
				tz: data.get('tz') as string,
			},
			guests: parseInt(data.get('guests') as string, 10),
		}),
	},
);
